/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    br: "br",
    ol: "ol",
    li: "li",
    em: "em",
    ul: "ul",
    h2: "h2",
    strong: "strong",
    h3: "h3",
    del: "del",
    code: "code"
  }, _provideComponents(), props.components), {Divider, Boss, Phase, CMInformation, Information, InformationBlock, Boon, Skill, Tabs, Tab, Warning, IdealRotation, Specialization, Instability, Condition, Control, Trait, SpecialActionKey, Item} = _components;
  if (!Boon) _missingMdxReference("Boon", true);
  if (!Boss) _missingMdxReference("Boss", true);
  if (!CMInformation) _missingMdxReference("CMInformation", true);
  if (!Condition) _missingMdxReference("Condition", true);
  if (!Control) _missingMdxReference("Control", true);
  if (!Divider) _missingMdxReference("Divider", true);
  if (!IdealRotation) _missingMdxReference("IdealRotation", true);
  if (!Information) _missingMdxReference("Information", true);
  if (!InformationBlock) _missingMdxReference("InformationBlock", true);
  if (!Instability) _missingMdxReference("Instability", true);
  if (!Item) _missingMdxReference("Item", true);
  if (!Phase) _missingMdxReference("Phase", true);
  if (!Skill) _missingMdxReference("Skill", true);
  if (!SpecialActionKey) _missingMdxReference("SpecialActionKey", true);
  if (!Specialization) _missingMdxReference("Specialization", true);
  if (!Tab) _missingMdxReference("Tab", true);
  if (!Tabs) _missingMdxReference("Tabs", true);
  if (!Trait) _missingMdxReference("Trait", true);
  if (!Warning) _missingMdxReference("Warning", true);
  return React.createElement(React.Fragment, null, React.createElement(Divider, {
    text: "98 CM"
  }), "\n", React.createElement(Boss, {
    name: "mama",
    video: "8tsjCFLxq-I",
    videoCreator: "Zyp [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusüion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Focus",
    weapon2OffSigil: "Force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    hideNotes: true,
    video: "8tsjCFLxq-I",
    videoCreator: "Zyp [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainInfusüion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46761",
    weapon2OffSigilId: "24615"
  }), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Overview"
  }, React.createElement(Information, null, React.createElement(_components.p, null, "The cc schedule for this fight varies depending on teamcomp. Check out the ", React.createElement(_components.a, {
    href: "/guides/cc-distribution/"
  }, "CC Distribution"), " page for more details.")), React.createElement(InformationBlock, {
    title: "General"
  }, React.createElement(_components.p, null, "As a firebrand your job during this encounter, besides helping with CC and dps, is to upkeep ", React.createElement(Boon, {
    name: "quickness"
  }), ". You also wnat to help out by supplying ", React.createElement(Boon, {
    name: "Aegis"
  }), " and ", React.createElement(Boon, {
    name: "Stability"
  }), " on demand."), React.createElement(_components.p, null, "The precast supplies you and your group with a lot of ", React.createElement(Boon, {
    name: "Quickness"
  }), " from the get-go, so you can focus on using ", React.createElement(Skill, {
    name: "Mantra of Solace",
    id: "41714"
  }), " to negate MAMAs spin attacks with ", React.createElement(Boon, {
    name: "Aegis"
  }))), React.createElement(InformationBlock, {
    title: "Precasting"
  }, React.createElement(_components.p, null, "There are two main approaches to precasting on MAMA, you can precast your ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " or you can stack symbols.\nThese approaches have a slightly different opener, the rotation seen here is written with the symbol-stack in mind."), React.createElement(Tabs, null, React.createElement(Tab, {
    title: "Symbol stacking"
  }, React.createElement(_components.p, null, "The goal is to place as many symbols as possible before the boss becomes vulnerable.", React.createElement(_components.br), "\n", "If you decide to precast symbols keep in mind the priority order: Mace > Scepter > Sw > Staff. If you have difficulties placing them all, you can drop the staff symbol."), React.createElement(_components.p, null, "Symbol precast shown in the example video:"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Mace ", React.createElement(Skill, {
    name: "Symbol of Faith",
    id: "9111"
  })), "\n", React.createElement(_components.li, null, "Scepter ", React.createElement(Skill, {
    name: "Symbol of Punishment",
    id: "9090"
  })), "\n", React.createElement(_components.li, null, "Focus ", React.createElement(Skill, {
    name: "shieldofwrath",
    id: "9082"
  })), "\n", React.createElement(_components.li, null, "Sword ", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  })), "\n", React.createElement(_components.li, null, "Staff ", React.createElement(Skill, {
    name: "Symbol of Swiftness",
    id: "9143"
  })), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "swap gear template"), " and do your opener"), "\n"), React.createElement(Warning, null, React.createElement(_components.p, null, "Note that you have to position yourself correctly so the symbols don't put you in combat before are finished."))), React.createElement(Tab, {
    title: "Tome opener"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Start on sword set"), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), React.createElement("br"), React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), React.createElement("br"), React.createElement(Skill, {
    name: "epilogueashesofthejust",
    id: "42898"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbolofblades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shieldofwrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "weaponswap to place ", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), " while she is still invulnerable."), "\n"))))), React.createElement(IdealRotation, {
    title: "Prestack"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Blast a fire field to generate ", React.createElement(Boon, {
    name: "Might"
  }), " (", React.createElement(Skill, {
    name: "Hallowed Ground",
    id: "9253"
  }), " or ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), "+", React.createElement(Skill, {
    name: "saveyourselves",
    id: "9085"
  }), " for ", React.createElement(Boon, {
    name: "Resolution"
  }), " uptime)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), ", ", React.createElement(Skill, {
    name: "mantra of potence",
    id: "40915"
  }), " and ", React.createElement(Skill, {
    name: "mantra of solace",
    id: "41714"
  }), " to generate ", React.createElement(Boon, {
    name: "Quickness"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "BaneSignet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take the ", React.createElement(_components.em, null, "Mistlock Singularity"), " and move to the boss"), "\n"))), "\n", React.createElement("br"), "\n", React.createElement(Tabs, null, React.createElement(Tab, {
    title: "Double Soulbeast comp"
  }, React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "leap of faith",
    id: "9080"
  }), "\n"), "\n")), React.createElement(IdealRotation, {
    title: "Knight 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "pull",
    id: "9226"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Strike",
    id: "9137"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Vengeful Strike",
    id: "9138"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Wrathful Strike",
    id: "9139"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n"), React.createElement(_components.p, null, "if Mama is about to phase (50% HP), keep ", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), " for either the add, or better, for phase 3")), React.createElement(IdealRotation, {
    title: "Knight 2"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Wrath",
    id: "9105"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Sword Arc",
    id: "9106"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Sword Wave",
    id: "9227"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n")), React.createElement(IdealRotation, {
    title: "Knight 3"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "pull",
    id: "9226"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Strike",
    id: "9137"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Vengeful Strike",
    id: "9138"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Wrathful Strike",
    id: "9139"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4"
  }, React.createElement(_components.p, null, "For the final phase you want to drop some ", React.createElement(Boon, {
    name: "Stability"
  }), " from ", React.createElement(Skill, {
    name: "tome of courage",
    id: "42259"
  }), " as well as dropping a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " from ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " for stacking ", React.createElement(Boon, {
    name: "Might"
  }), " before MAMA reaches the party (Ideally right when the third add dies)"), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n", "if the boss is not dead use what you have on your sword set."), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n")))), React.createElement(Tab, {
    title: "Double Guardian comp"
  }, React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "leap of faith",
    id: "9080"
  }), "\n"), "\n")), React.createElement(IdealRotation, {
    title: "Knight 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Strike",
    id: "9137"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Vengeful Strike",
    id: "9138"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Wrathful Strike",
    id: "9139"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n"), React.createElement(_components.p, null, "if Mama is about to phase (50% HP), keep ", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), " for either the add, or better, for phase 3")), React.createElement(IdealRotation, {
    title: "Knight 2"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Sword of Wrath",
    id: "9105"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Sword Arc",
    id: "9106"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Sword Wave",
    id: "9227"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ChapterHeatedRebuke",
    id: "42449"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n")), React.createElement(IdealRotation, {
    title: "Knight 3"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "pull",
    id: "9226"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Strike",
    id: "9137"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Vengeful Strike",
    id: "9138"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Wrathful Strike",
    id: "9139"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), "\n"), "\n"))), React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 4"
  }, React.createElement(_components.p, null, "For the final phase you want to drop some ", React.createElement(Boon, {
    name: "Stability"
  }), " from ", React.createElement(Skill, {
    name: "tome of courage",
    id: "42259"
  }), " as well as dropping a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " from ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " for stacking ", React.createElement(Boon, {
    name: "Might"
  }), " before MAMA reaches the party (Ideally right when the third add dies). If your party is a bit slow on the cc, you can also squeeze in a ", React.createElement(Skill, {
    name: "ChapterHeatedRebuke",
    id: "42449"
  }), "."), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n", "if the boss is not dead use what you have on your sword set."), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n"))))), "\n", React.createElement(Divider, {
    text: "98 CM"
  }), "\n", React.createElement(Boss, {
    name: "siax",
    video: "zLE72fLTt1Q",
    videoCreator: "Danni [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "Serpentslaying",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Focus",
    weapon2OffSigil: "Serpentslaying",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    video: "zLE72fLTt1Q",
    videoCreator: "Danni [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24658",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46761",
    weapon2OffSigilId: "24658"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Use your ", React.createElement(Skill, {
    name: "Binding Blade",
    id: "9147"
  }), " inside Siax' hitbox to get 5 additional hits. This is the reason why ", React.createElement(Specialization, {
    name: "Guardian"
  }), " is very strong at this boss. Do not miss this."), "\n", React.createElement(_components.li, null, "Use one ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), " at the start of every phase and save one ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), " for every add."), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Bane Signet",
    id: "9093"
  }), " to help with CC and buff your party."), "\n", React.createElement(_components.li, null, "Use the ", React.createElement(Boon, {
    name: "Aegis"
  }), " from ", React.createElement(Skill, {
    name: "Mantra of Solace",
    id: "41714"
  }), " to block the expanding AoEs for your team."), "\n")), "\n", React.createElement(_components.h2, null, React.createElement(_components.strong, null, "General Information")), "\n", React.createElement(_components.p, null, "Daily strategies for Siax can vary heavily depending on group composition, dps, and expected kill times."), "\n", React.createElement(Warning, null, React.createElement(_components.p, null, "It is possible to replace the ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " with an additional ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " on this fight. This will only work in double ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " comps and only if the kill is short (a rough kill-time estimate would be ~30 seconds).")), "\n", React.createElement(_components.p, null, "If you are staying as ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " then the rotation does not change too much between daily strats, specifically the cc and cc-skip ones."), "\n", React.createElement(_components.p, null, "There are three small differences between these strategies for you."), "\n", React.createElement(Tabs, null, React.createElement(Tab, {
    title: "CC"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast one instance of ", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), " before taking the mistlock, and then use it to break the breakbar when the pull starts"), "\n", React.createElement(_components.li, null, "Split 1 (at 66%) The two guardians go together, usually north"), "\n", React.createElement(_components.li, null, "Split 2 (at 33%) You will be helping the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " with the NW add, and then the ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " with the NE add"), "\n")), React.createElement(Tab, {
    title: "CC-Skip"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast two instances of ", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), ". One before taking the mistlock, and one after"), "\n", React.createElement(_components.li, null, "Split 1 (at 66%) you will be assisting one of the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), "s, usually north"), "\n", React.createElement(_components.li, null, "Split 2 (at 33%) you will be assisting both of the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), "s. On the NE add you will use ", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), " + ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " as you do in the cc-strat, but instead of helping the ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " finish the NE add you will be helping the second ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), "."), "\n"))), "\n", React.createElement(Information, null, React.createElement(_components.p, null, "The following written guide is based on the cc strategy, if you are skipping p1 cc, make the substitutions outlined above")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast symbols and/or ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " + ", React.createElement(Skill, {
    name: "epilogueashesofthejust",
    id: "42898"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Shield of wrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "weapon swap"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), " when the breakbar is up"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n")), React.createElement(IdealRotation, {
    title: "Split 1"
  }, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "leap of faith",
    id: "9080"
  }), " to the add"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Strike",
    id: "9137"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Vengeful Strike",
    id: "9138"
  }), "\n", React.createElement("br"), "\n", React.createElement(Skill, {
    name: "Wrathful Strike",
    id: "9139"
  }), "\n", "make sure the add is dead before moving back"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(_components.p, null, "The skill-order for phase 2 depends on how fast split 1 is, so be flexible and adapt the burst order to your need. The main thing you want to focus on is getting multiple hits of ", React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  }), " by casting it inside of Siax's hitbox while the exploding adds are in range."), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  }), " (Do not miss it)"), "\n", React.createElement(_components.li, null, "weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n"), React.createElement(_components.p, null, "Share ", React.createElement(Boon, {
    name: "Aegis"
  }), " to block the AoE and tailswipe when/if needed"), React.createElement(_components.p, null, "For the upcoming split phase you can either use ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " to help on NW or")), React.createElement(IdealRotation, {
    title: "Split 2"
  }, React.createElement(_components.p, null, React.createElement(_components.strong, null, "NW add")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "optional:"), " ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  })), "\n"), React.createElement(_components.p, null, React.createElement(_components.strong, null, "NE add")), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Sword of Wrath",
    id: "9105"
  }), React.createElement("br"), React.createElement(Skill, {
    name: "Sword Arc",
    id: "9106"
  }), React.createElement("br"), React.createElement(Skill, {
    name: "Sword Wave",
    id: "9227"
  }), React.createElement("br"), "until dead"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 3"
  }, React.createElement(_components.p, null, "As in phase 2 the skill-order should be modified to make sure you get multiple hits from ", React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  })), React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bane signet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealots defense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Shield of wrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "weapon swap"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " if you have time"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  }), " (Do not miss it)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Whirling Wrath",
    id: "9081"
  }), "\n"), "\n"))), "\n", React.createElement("br"), "\n", React.createElement(Divider, {
    text: "99 CM"
  }), "\n", React.createElement(Boss, {
    name: "Skorvald",
    video: "l3c5DxfiX14",
    videoCreator: "Princeps [dT]",
    foodId: "43360",
    utilityId: "73191",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    elite: "mantraofliberation",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Focus",
    weapon2OffSigil: "Force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    video: "l3c5DxfiX14",
    videoCreator: "Princeps [dT]",
    foodId: "43360",
    utilityId: "73191",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46761",
    weapon2OffSigilId: "24615",
    eliteId: "43357"
  }, React.createElement(_components.h3, null, "Utility skill variation:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " runs ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", you should play with ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "."), "\n", React.createElement(_components.li, null, "Else (for example when ", React.createElement(Specialization, {
    name: "Renegade"
  }), " plays ", React.createElement(Skill, {
    name: "Legendary Demon Stance",
    id: "28494"
  }), " due to ", React.createElement(Instability, {
    name: "No Pain, No Gain"
  }), ") take ", React.createElement(Skill, {
    name: "standyourground",
    id: "9153"
  }), " to grant ", React.createElement(Boon, {
    name: "Stability"
  }), " on the third island."), "\n"), React.createElement(_components.h3, null, "Elite skill variation:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If you do not have a ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), " that plays ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), " you need to take ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), " over ", React.createElement(Skill, {
    name: "mantraofliberation",
    id: "43357"
  })), "\n")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "When prestacking in fractals we want to stack as many useful boons with as much duration as possible, these being ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Swiftness"
  }), ". We can also cast useful unique effects, for a ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " this means your signets such as ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), "."), React.createElement(_components.p, null, "As soon as you see ", React.createElement(Skill, {
    name: "moastance",
    id: "45970"
  }), " from the ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " on your bar lay down a fire field with ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4), close the tome, blast with ", React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), " (Focus 5) and stack boons with your mantras. To maximize the duration of ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " use it as late as possible! You do not need to wait for the full aftercast before taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ".")), React.createElement(InformationBlock, {
    title: "At the boss"
  }, React.createElement(_components.p, null, "If your team coordinates via voice chat you should be able to ", React.createElement(_components.del, null, "precast"), " cause lags with your symbols.", React.createElement(_components.br), "\n", "If not you have to observe your fellow ", React.createElement(Specialization, {
    name: "Soulbeast"
  }), " friend's ", React.createElement(Skill, {
    name: "Barrage",
    id: "12469"
  }), " animation. As soon as it finishes you can assume that you will be put in combat."), React.createElement(_components.p, null, "The symbol precast priority is as always: Mace > Scepter > GS > Sw > Staff"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), " (Focus 5)"), "\n", React.createElement(_components.li, null, "1x ", React.createElement(Skill, {
    name: "Mantra of solace",
    id: "41714"
  })), "\n", React.createElement(_components.li, null, "3x ", React.createElement(Skill, {
    name: "Mantra of potence",
    id: "40915"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "BaneSignet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and approach boss"), "\n", React.createElement(_components.li, null, "Symbol precast"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), " (Focus 5)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "Phase 1 starts right when you finished your symbol precast. Immediately use a ", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), " (ideally while you are still out of combat) to ramp up ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " as quick as possible. Either enable \"Autotargeting\" in the options or spam \"Take target\" (tab) while casting your ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), "."), React.createElement(_components.p, null, "Cast ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " before swapping to your greatsword, because it applies ticking damage and is therefore more valuable in combination with ", React.createElement(Skill, {
    name: "Onewolfpack",
    id: "45717"
  }), ". Swap to greatsword and finish the phase with as many skills as you can."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Swap")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbolofresolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), "\n"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Islands"
  }, React.createElement(InformationBlock, {
    title: "Island 1"
  }, React.createElement(_components.p, null, "You want to save your ", React.createElement(Skill, {
    name: "Leapoffaith",
    id: "9080"
  }), " for the rift to the second island. Therefore you will most likely arrive last on the first island. Make sure to share ", React.createElement(Boon, {
    name: "Aegis"
  }), " and ", React.createElement(Boon, {
    name: "Quickness"
  }), " against the ", React.createElement(Control, {
    name: "Knockback"
  }), " from the anomaly. Since you are last you have a good aim at your fellow friends and everyone will get the ", React.createElement(Boon, {
    name: "Aegis"
  }), ". Finish the add with ", React.createElement(Skill, {
    name: "Whirlingwrath",
    id: "9081"
  }), ".", React.createElement(_components.br), "\n", "For fast transitioning leap with ", React.createElement(Skill, {
    name: "Leapoffaith",
    id: "9080"
  }), " towards the left side of the rift.")), React.createElement(InformationBlock, {
    title: "Island 2"
  }, React.createElement(_components.p, null, "Drop a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4) right when you land so your party has the chance to blast some ", React.createElement(Boon, {
    name: "Might"
  }), ". While the anomaly is evading you can drop your ", React.createElement(Skill, {
    name: "symbolofresolution",
    id: "9146"
  }), " and start casting ", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), " just before you jump to avoid the ", React.createElement(Control, {
    name: "Knockback"
  }), " circle attack.", React.createElement(_components.br), "\n", "Again, for fast transitioning leap with ", React.createElement(Skill, {
    name: "Leapoffaith",
    id: "9080"
  }), " towards the left side of the rift and swap weapons in the rift.")), React.createElement(InformationBlock, {
    title: "Island 3"
  }, React.createElement(_components.p, null, "Drop a ", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), " as soon as you land to quickly ramp up ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " up. This is key to kill the anomaly quickly! In case your team can't kill the anomaly before the first ", React.createElement(Control, {
    name: "Knockback"
  }), " attack happens you should preemptively apply ", React.createElement(Boon, {
    name: "Stability"
  }), " with ", React.createElement(Skill, {
    name: "Mantraofliberation",
    id: "43357"
  }), ". Use ", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), " to keep up your stacks of ", React.createElement(Trait, {
    name: "Symbolicavenger",
    id: "2017"
  }), ". Before you enter the rift open your ", React.createElement(Skill, {
    name: "tomeofjustice",
    id: "44364"
  }), "!")), React.createElement(InformationBlock, {
    title: "Island 4"
  }, React.createElement(_components.p, null, "Drop a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4) right when you land so your party has the chance to blast some ", React.createElement(Boon, {
    name: "Might"
  }), ". While the anomaly is evading blast ", React.createElement(Boon, {
    name: "Might"
  }), " with ", React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), ". Share your ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " already! This is not an issue since phase 2 and 3 are each about 6-8 seconds long; so for the majority of the fight ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " will be up. Just before the anomaly finishes its evade frame drop a ", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), " for quick ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " ramp-up.", React.createElement(_components.br), "\n", "During our testing applying one stack of ", React.createElement(Boon, {
    name: "Stability"
  }), " with ", React.createElement(Skill, {
    name: "Mantraofliberation",
    id: "43357"
  }), " was not enough to reliably not get knockbacked by the anomaly, so it is recommended to jump!", React.createElement(_components.br), "\n", "You should stay at the anomaly till the very last moment since you do not have any important skills to precast on skorvald.")))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2 and 3"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "Cycle through your dps skill starting with ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4) due to it being a ticking damage skill. Swap to greatsword and finish the phase with as many skills as you can."), React.createElement(_components.p, null, "This time around feel free to use ", React.createElement(Skill, {
    name: "Leapoffaith",
    id: "9080"
  }), " to get to the island quicker since the party will travel via portals now!")), React.createElement(InformationBlock, {
    title: "Rest of the fight"
  }, React.createElement(_components.p, null, "Use the information from above for the remaining encounter!"))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Zealotsdefense",
    id: "9107"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of blades",
    id: "9097"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Swap")), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbolofresolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), "\n"), "\n"))), "\n", React.createElement(Divider, {
    text: "99 CM"
  }), "\n", React.createElement(Boss, {
    name: "Artsariiv",
    video: "1KDJjkkGw70",
    videoCreator: "Princeps [dT]",
    foodId: "91805",
    utilityId: "73191",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "Force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Focus",
    weapon2OffSigil: "Force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    video: "1KDJjkkGw70",
    videoCreator: "Princeps [dT]",
    foodId: "91805",
    utilityId: "73191",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainInfusion1Id: "37131",
    weapon1MainInfusion2Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46761",
    weapon2OffSigilId: "24615"
  }, React.createElement(_components.p, null, "Rotation on Artsariiv depends on whether your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " runs ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", ", React.createElement(Skill, {
    name: "Legendary Assassin Stance",
    id: "28134"
  }), " or ", React.createElement(Skill, {
    name: "Legendary Demon Stance",
    id: "28494"
  }), ". If your ", React.createElement(Specialization, {
    name: "Renegade"
  }), " runs ", React.createElement(Skill, {
    name: "Legendary Dwarf Stance",
    id: "28419"
  }), ", you can take ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), ". In all other cases take ", React.createElement(Skill, {
    name: "standyourground",
    id: "9153"
  }), ".")), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Precast"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "When prestacking in fractals we want to stack as many useful boons with as much duration as possible, these being ", React.createElement(Boon, {
    name: "Might"
  }), ", ", React.createElement(Boon, {
    name: "Fury"
  }), ", ", React.createElement(Boon, {
    name: "Quickness"
  }), ", ", React.createElement(Boon, {
    name: "Alacrity"
  }), " and ", React.createElement(Boon, {
    name: "Swiftness"
  }), ". We can also cast useful unique effects, for a ", React.createElement(Specialization, {
    name: "Firebrand"
  }), " this means your signets such as ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), "."), React.createElement(_components.p, null, "Ideally everyone waits at Artsariiv together to give the ", React.createElement(Specialization, {
    name: "Renegade"
  }), " more time to ", React.createElement(_components.del, null, "precast"), " lag the game with casting ", React.createElement(Boon, {
    name: "Resistance"
  }), ". Take the portal and lay down a fire field with ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4), close the tome, blast with ", React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), " (Focus 5), ", React.createElement(Skill, {
    name: "hammerofwisdom",
    id: "9125"
  }), " and stack boons with your mantras. To maximize the duration of ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " use it as late as possible! You do not need to wait for the full aftercast before taking the ", React.createElement(_components.em, null, "Mistlock Singularity"), ". If you are very quick, cast ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  }), " again, however, this is only worth it if your party instatriggers the boss.")), React.createElement(InformationBlock, {
    title: "Portal to the boss"
  }, React.createElement(_components.p, null, "Immediately start precasting your symbols. The symbol precast priority is as always: Mace > Scepter > GS > Sw > Staff. Make sure to end on greatsword to be able to proceed optimally."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Shieldofwrath",
    id: "9082"
  }), " (Focus 5)"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Hammerofwisdom",
    id: "9125"
  }), "\n"), "\n", React.createElement(_components.li, null, "1x ", React.createElement(Skill, {
    name: "Mantra of solace",
    id: "41714"
  })), "\n", React.createElement(_components.li, null, "3x ", React.createElement(Skill, {
    name: "Mantra of potence",
    id: "40915"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "BaneSignet",
    id: "9093"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take ", React.createElement(_components.em, null, "Mistlock Singularity"), " and take portal"), "\n", React.createElement(_components.li, null, "Symbol precast"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 1"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "Phase 1 starts right when you finished your symbol precast. Immediately use a ", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), " (ideally while you are still out of combat) to ramp up ", React.createElement(Condition, {
    name: "Vulnerability"
  }), " as quick as possible. The first skill you should be using is ", React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  }), "! Due to multiple targets around the boss this skill will hit up to 5 times provided you use it in the bosses hitbox. While casting ", React.createElement(Skill, {
    name: "binding blade",
    id: "9147"
  }), ", connect with ", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), " immediately; already start moving through the hitbox of the box. ", React.createElement(Skill, {
    name: "Whirlingwrath",
    id: "9081"
  }), " hits more times if used in the hitbox."), React.createElement(_components.p, null, "Switch weapons as soon as possible and cast ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " first, because it applies ticking damage and is therefore more valuable in combination with ", React.createElement(Skill, {
    name: "Onewolfpack",
    id: "45717"
  }), ". You might need to turn your character a bit towards the boss to be able to cast the skill. Finish the phase with ", React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), " and ", React.createElement(Skill, {
    name: "symbolofblades",
    id: "9097"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " (Gs 5)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  }), " (Gs 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), " (Sword 3)"), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "CC phase"
  }, React.createElement(InformationBlock, {
    title: "Middle Add"
  }, React.createElement(_components.p, null, "Open a ", React.createElement(Skill, {
    name: "Tomeofjustice",
    id: "44364"
  }), " and use ", React.createElement(Skill, {
    name: "Chapter 3: Heated Rebuke",
    id: "42449"
  }), ". Don't close your tome yet.")), React.createElement(InformationBlock, {
    title: "Southeast add"
  }, React.createElement(_components.p, null, "Use ", React.createElement(Skill, {
    name: "Banesignet",
    id: "9093"
  }), " as soon as you see the rock gazelle charging with ", React.createElement(Skill, {
    id: "43636",
    id: "43636"
  }), " at the add.", React.createElement(_components.br), "\n", "Drop a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4) right after so your party has the chance to blast some ", React.createElement(Boon, {
    name: "Might"
  }), ". If your party is slow you can blast the fire field with ", React.createElement(Skill, {
    name: "Shield of wrath",
    id: "9082"
  }), "! Dont forget to cast ", React.createElement(Boon, {
    name: "Quickness"
  }), " for your party (they ", React.createElement(_components.del, null, "precast"), " cause lag)."), React.createElement(_components.p, null, "It is advisable to precast ", React.createElement(Skill, {
    name: "epilogueashesofthejust",
    id: "42898"
  }), " as well since it will deal about 30k damage in ", React.createElement(Condition, {
    name: "Burning"
  }), ", which is substantial for the ", React.createElement(Specialization, {
    name: "Firebrand",
    text: "Power Firebrand"
  }), ".")))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "Phase 2"
  }, React.createElement(InformationBlock, {
    title: "Overview"
  }, React.createElement(_components.p, null, "Make sure to step back so that you will puke with your ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " on your party to give ", React.createElement(Boon, {
    name: "Aegis"
  }), " against the expanding circle ", React.createElement(Control, {
    name: "Knockback"
  }), " attack.", React.createElement(_components.br), "\n", "Cycle through your dps skill starting with ", React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4) due to it being a ticking damage skill. Swap to greatsword and finish the phase with as many skills as you can."), React.createElement(_components.p, null, "Before following Artsariiv to the corner it is expected to heal the ", React.createElement(Skill, {
    name: "Frostspirit",
    id: "12497"
  }), " with ", React.createElement(Skill, {
    name: "tomeofresolve",
    id: "41780"
  }), ": ", React.createElement(Skill, {
    name: "Epilogue: Eternal Oasis",
    id: "42925"
  }), ", ", React.createElement(Skill, {
    name: "Chapter 4: Shining River",
    id: "42008"
  }), ", ", React.createElement(Skill, {
    name: "Chapter 3: Azure Sun",
    id: "45128"
  }), ". Use your ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to jump to Artsariiv."), React.createElement(_components.p, null, "Finish the phase on greatsword. Do not swap to sword since you will need to use your CC abilities on greatsword to complete the split phase quickly. In case your team is slow you can carry them by reflecting Artsariiv's ", React.createElement(_components.code, null, "Taw Shot"), " attack with ", React.createElement(Skill, {
    name: "Chapter 3: Valiant Bulwark",
    id: "41836"
  }), "."))), React.createElement(IdealRotation, null, React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Swordofjustice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Ray of Judgment",
    id: "9112"
  }), " (Focus 4)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Zealotsdefense",
    id: "9107"
  }), " (Sword 3)"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbol of blades",
    id: "9097"
  }), " (Sword 2)"), "\n", React.createElement(_components.li, null, React.createElement(_components.em, null, "Swap")), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbolofresolution",
    id: "9146"
  }), " -> ", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  })), "\n", React.createElement(_components.li, null, "Heal spirit and ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to the side"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "Symbolofresolution",
    id: "9146"
  }), " -> ", React.createElement(Skill, {
    name: "whirlingwrath",
    id: "9081"
  })), "\n"))), "\n", React.createElement(Phase, null, React.createElement(CMInformation, {
    title: "CC phase"
  }, React.createElement(InformationBlock, {
    title: "Middle Add"
  }, React.createElement(_components.p, null, "Open a ", React.createElement(Skill, {
    name: "Tomeofjustice",
    id: "44364"
  }), " and use ", React.createElement(Skill, {
    name: "Chapter 3: Heated Rebuke",
    id: "42449"
  }), ". Don't close your tome yet.")), React.createElement(InformationBlock, {
    title: "Southwest adds"
  }, React.createElement(_components.p, null, "Use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " as soon as you can to jump to the two southwest adds. You will cc these two adds together with the ", React.createElement(Specialization, {
    name: "Dragonhunter"
  }), ". Since ", React.createElement(Skill, {
    name: "Bindingblade",
    id: "9147"
  }), " for CC cannot applied at the same time you need to hurry to not slow down your party. Leap with ", React.createElement(Skill, {
    name: "leapoffaith",
    id: "9080"
  }), " against the corder southwest add, swap weapons immediately and open ", React.createElement(Skill, {
    name: "tomeofjustice",
    id: "44364"
  }), " and cc the west add with ", React.createElement(Skill, {
    name: "Chapter 3: Heated Rebuke",
    id: "42449"
  }), "."), React.createElement(_components.p, null, "Jump back into the middle while still having targeted the west add. Cast ", React.createElement(Skill, {
    name: "feelmywrath",
    id: "29965"
  }), " so your party friends can ", React.createElement(_components.del, null, "precast"), " lag the game. Finish the west add with ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  }), "."), React.createElement(_components.p, null, "Drop a ", React.createElement(Skill, {
    name: "chapterscorchedaftermath",
    id: "40015"
  }), " (F1-4) right after so your party has the chance to blast some ", React.createElement(Boon, {
    name: "Might"
  }), ". Before closing the tome use ", React.createElement(Skill, {
    name: "epilogueashesofthejust",
    id: "42898"
  }), ". Blast the fire field with ", React.createElement(Skill, {
    name: "Shield of wrath",
    id: "9082"
  }), " and finish the fight as described in phase 2.")))), "\n", React.createElement(Divider, {
    text: "BOSSNAME"
  }), "\n", React.createElement(Boss, {
    name: "Arkk",
    video: "gvgezfhaAE4",
    videoCreator: "Danni [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainAffix: "Berserker",
    weapon1MainType: "Greatsword",
    weapon1MainSigil1: "force",
    weapon1MainSigil2: "Impact",
    weapon1MainInfusion1Id: "37131",
    weapon2OffAffix: "Berserker",
    weapon2OffType: "Focus",
    weapon2OffSigil: "Force",
    weapon2OffInfusionId: "37131",
    weapon2MainAffix: "Berserker",
    weapon2MainType: "Sword",
    weapon2MainSigil1: "Impact",
    weapon2MainInfusion1Id: "37131",
    video: "gvgezfhaAE4",
    videoCreator: "Danni [dT]",
    foodId: "43360",
    utilityId: "50082",
    healId: "41714",
    utility1Id: "40915",
    utility2Id: "9168",
    utility3Id: "9093",
    eliteId: "29965",
    weapon1MainInfusion1Id: "37131",
    weapon2OffInfusionId: "37131",
    weapon2MainInfusion1Id: "37131",
    weapon1MainId: "46762",
    weapon1MainSigil1Id: "24615",
    weapon1MainSigil2Id: "24868",
    weapon2MainId: "46774",
    weapon2MainSigil1Id: "24868",
    weapon2OffId: "46761",
    weapon2OffSigilId: "24615"
  }, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Play scepter on this encounter"), "\n", React.createElement(_components.li, null, "Place markers for the blooms"), "\n", React.createElement(_components.li, null, "You can run ", React.createElement(Item, {
    id: "43360",
    id: "43360"
  }), " if you are able to damage the first anomaly and adds in the later phases."), "\n")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Precast")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " skill 4 and 5"), "\n", React.createElement(_components.li, null, "Blast the fire field with cast ", React.createElement(Skill, {
    name: "Holy Strike",
    id: "9140"
  }), " or ", React.createElement(Skill, {
    name: "mightyblow",
    id: "9194"
  })), "\n", React.createElement(_components.li, null, "Use 3 charges of ", React.createElement(Skill, {
    name: "mantraofpotence",
    id: "40915"
  }), " and share ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " (keep in mind that ", React.createElement(Skill, {
    name: "mantraofsolace",
    id: "41714"
  }), " only shares ", React.createElement(Boon, {
    name: "Quickness"
  }), " once every 7 seconds thanks to ", React.createElement(Trait, {
    name: " liberators vow",
    id: "2101"
  }), ")"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Stand your ground",
    id: "9153"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  })), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  })), "\n", React.createElement(_components.li, null, "Take mistlock"), "\n", React.createElement(_components.li, null, "Use ", React.createElement(Skill, {
    name: "Feelmywrath",
    id: "29965"
  }), " and ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  }), " again!"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " skill 4"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ray of judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shield of wrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "Weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), "\n"), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), " if not phased yet"), "\n", React.createElement(_components.li, null, "Take your orb and stay on greatsword"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 2")), "\n", React.createElement(_components.p, null, "You can use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to the middle"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phase 1")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), " to apply ", React.createElement(Condition, {
    name: "vulnerability"
  })), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " -> ", React.createElement(Skill, {
    name: "Pull",
    id: "9226"
  }), " 2 mobs"), "\n", React.createElement(_components.li, null, "You can enter ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " and pull (skill 3) the mobs closer to the middle, then use skill 4"), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  }), " as the add is about to die"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 3")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "Weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shield of wrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ray of judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take your orb and stay on sword"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 4")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ray of judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "Weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.em, null, "Add Phase 2")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), " to apply ", React.createElement(Condition, {
    name: "vulnerability"
  })), "\n", React.createElement(_components.li, null, React.createElement(Skill, {
    name: "bindingblade",
    id: "9147"
  }), " -> ", React.createElement(Skill, {
    name: "Pull",
    id: "9226"
  }), " 2 mobs"), "\n", React.createElement(_components.li, null, "You can enter ", React.createElement(Skill, {
    name: "tome of justice",
    id: "44364"
  }), " and pull (skill 3) the mobs closer to the middle, then use skill 4"), "\n", React.createElement(_components.li, null, "Cast ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  }), " as the add is about to die"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 5")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n", React.createElement(_components.li, null, "Weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "Take your orb and stay on sword"), "\n"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Phase 6")), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Precast ", React.createElement(Skill, {
    name: "banesignet",
    id: "9093"
  })), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "shield of wrath",
    id: "9082"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "sword of justice",
    id: "9168"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "ray of judgment",
    id: "9112"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "symbol of blades",
    id: "9097"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "zealotsdefense",
    id: "9107"
  }), "\n"), "\n", React.createElement(_components.li, null, "Weapon swap"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "Symbol of Resolution",
    id: "9146"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(Skill, {
    name: "whirling wrath",
    id: "9081"
  }), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Annotation:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "If possible make sure you use ", React.createElement(SpecialActionKey, {
    name: "hypernovalaunch"
  }), " to CC the adds"), "\n", React.createElement(_components.li, null, "The archdiviner spawns AoE's that strips boons, avoid them!"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
